import { IResponsibleGamingConfig } from '../me/autoexclusion';
import { registrationMode } from '../me/registration';
import { ScriptTag } from './scriptTag';

export class Layout {
  footer?: 'center' | 'fullWidth' = 'center';
  header?: 'left' | 'center' | 'right' = 'left';
  lobby?: 'grid' | 'default' = 'default';
}

export class EnvironmentConfig {
  project?: string;
  production: boolean;
  api: {
    endpoint?: string;
    accounts?: string;
    ws?: string;
    /** @deprecated*/
    boe?: string;
    catalogs: string;
    cms: string;
    games?: string;
    identity: string;
    reports?: string;
    sportsbook?: string;
    promo?: string;
    wp?: string
  };
  appVersion: string;
  bookmakerId: number;
  webAppId: number;
  defaultLangCode: string;
  mobileApp?: boolean;
  externalScripts?: Array<ScriptTag>;
  minAge?: number;
  features?: {
    betSlip?: {
      hideIntegral?: boolean;
      autoPrint: boolean;
      fastBet: boolean;
      hideCountDown: boolean;
      selectionsByDate: boolean;
      bookBet: boolean;
      showQrCode?: boolean;
      showBarCode?: boolean;
    },
    headerLayout?: 'header1' | 'header2' | 'header3' | 'header4' | string;
    games?: any;
    hideLiveWidget?: boolean;
    jackpots?: boolean,
    casinoMode?: 'lite' | string;
    showBalance?: boolean;
    hideRegistration?: boolean;
    bottomSheet?: boolean;
    hideAmountsPreset?: boolean;
    creationModes?: {
      agent?: registrationMode,
      player?: registrationMode,
      shop?: registrationMode
    };
    userSportHomeTab?: number;
    defaultPlayNow?: string;
    hideGender?: boolean;
    hideProvinces?: boolean;
    liveStreaming?: boolean;
    showCouponCheck?: boolean;
    showTopWinnings?: boolean;
    showNoBalanceDialog?: boolean;
    allowThemeSwitch?: boolean;
    bookBet?: boolean;
    showMarketSelector?: boolean;
    taxType?: {
      id: number,
      winTax?: {
        percentage: number;
        threshold?: number;
      },
      stakeTax?: {
        percentage: number;
      },
    }
    registrationModes?: registrationMode;
    defaultPrefix?: string;
    passwordRecovery?: {
      phone?: boolean;
      email?: boolean;
    },
    geolocation?: {
      allowed?: Array<{ country: string, states: Array<string> }>;
      registrations: Array<{ country: string, mode: registrationMode }>
    },
    validators?: any;
    documentNeeded?: boolean;
    responsibleGaming?: IResponsibleGamingConfig,
    ogImage?: string;
  };
  homePage: string;
  depositPage?: string;
  paystackKey?: string;
  onesignalKey?: string;
  pixelTracking?: any;
  langs: Array<{ code: string, name: string, code4?:string }>;
  promotionPage?: string;
  payments?: Array<{name: string}>;
  liveChats?: {
    liveChatInc?: number;
    tawkUrl?: string;
    lineChat?: string;
  };
  layout?: Layout;
  seamless?: string;
  theme?: any;
  websocketUrl?: {
    live: string;
    coupon: string;
  };
  live?: {
    matchTrackerSideBar?: {
      provider?: string,
      active: boolean,
      url: string
    }
  };
  keys?: {
    googleApiKey?: string,
    oddInBrandToken?: string;
    altenar?: string;
    parent_affiliate_id?: number;
    tap_parent_affiliate_id?: string;
  }
  reportServerEndpoint?: string;
  timeZone: string;
  piqMerchantId?: string;
  trackers?: Array<{ name: string, url: string }>;
}
